import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { gaAddToCart, gaRemoveFromCart } from '../../shared/helpers/googleAnalytics'

const initialState = {
  debug: false,
  status: 'fresh',
  loaded: false,
  loading: false,
  error: null,
  open: false,
  user_id: null,
  customer: null,
  order: null,
  stripeCustomer: null,
  payment_success: null,
  order_confirmation_id: null,
  shipping_costs: null,
  paymentRequestOpen: false,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setError: (state, action) => {
      state.error = action.payload
    },

    setOpen: (state, action) => {
      state.open = Boolean(action.payload)
      document.body.classList[action.payload ? 'add' : 'remove']('noscroll')
    },

    setUserId: (state, action) => {
      state.user_id = action.payload
    },

    setPaymentSuccess: (state, action) => {
      state.payment_success = action.payload
    },

    setOrderConfirmationId: (state, action) => {
      state.order_confirmation_id = action.payload
    },

    setCustomer: (state, action) => {
      state.customer = action.payload
    },

    setOrder: (state, action) => {
      state.order = action.payload
    },

    setStripeCustomer: (state, action) => {
      state.stripeCustomer = action.payload
    },

    setShippingCosts: (state, action) => {
      state.shipping_costs = action.payload
    },

    setPaymentRequestOpen: (state, action) => {
      state.paymentRequestOpen = action.payload
    },
  },
})

export const {
  setCustomer,
  setOrder,
  setStripeCustomer,
  setCheckoutUrl,
  setOrderConfirmationId,
  setLoading,
  setError,
  setOpen,
  setShippingCosts,
  setPaymentRequestOpen,
  setPaymentSuccess,
  setUserId,
} = cartSlice.actions

export const selectLoaded = (state) => state.cart.loaded
export const selectLoading = (state) => state.cart.loading
export const selectError = (state) => state.cart.error
export const selectStatus = (state) => state.cart.status
export const selectOpen = (state) => state.cart.open
export const selectUserId = (state) => state.cart.user_id
export const selectContents = (state) => state.cart.contents
export const selectCustomer = (state) => state.cart.customer
export const selectOrder = (state) => state.cart.order
export const selectShippingCosts = (state) => state.cart.shipping_costs
export const selectStripeCustomer = (state) => state.cart.stripeCustomer
export const selectPaymentSuccess = (state) => state.cart.payment_success
export const selectOrderConfirmationId = (state) => state.cart.order_confirmation_id
export const selectContainsAMealPlan = (state) => state.cart.contains_a_meal_plan
export const selectPaymentRequestOpen = (state) => state.cart.paymentRequestOpen

export const fetchCustomer = (user_id) => async (dispatch, getState) => {
  axios({
    method: 'get',
    url: `/api/users/${user_id}`,
  })
    .then(({ data: customer }) => {
      dispatch(setCustomer(customer))
    })
}

export const createCart = async (accessToken, product, quantity, subscription, mutate, dispatch, membershipOption) => {
  axios({
    method: 'post',
    url: '/api/cart',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      attributes: { user_id: null },
      cart: {
        contents: [
          {
            data: product,
            quantity,
            subscription,
          },
        ],
      },
      membership_option: membershipOption,
    },
  })
    .then(({ data }) => {
      window.localStorage.setItem('cart', data.uuid)
      mutate()
    })
    .then(() => gaAddToCart(product, quantity))
    .then(() => dispatch(setLoading(false)))
}

export const addItemToCart = async (accessToken, cart, product, quantity, subscription, mutate, dispatch, membershipOption) => axios({
  method: 'post',
  url: `/api/cart/${cart.uuid}/cart_items`,
  headers: { 'X-CSRF-Token': accessToken },
  data: {
    sku: product.sku, quantity, subscription, membership_option: membershipOption,
  },
})
  .then(() => mutate())
  .then(() => gaAddToCart(product, quantity))
  .then(() => dispatch(setLoading(false)))

export const incrementCart = async (accessToken, cart, product, existingItem, mutate, dispatch) => {
  const quantity = existingItem.quantity + 1

  axios({
    method: 'patch',
    url: `/api/cart/${cart.uuid}/cart_items/${product.id}`,
    headers: { 'X-CSRF-Token': accessToken },
    data: { quantity },
  })
    .then(() => mutate())
    .then(() => gaAddToCart(product, 1))
    .then(() => dispatch(setLoading(false)))
}

export const handleRemove = async (accessToken, cart, product, mutate, dispatch, quantity) => {
  axios({
    method: 'delete',
    url: `/api/cart/${cart.uuid}/cart_items/${product.id}`,
    headers: { 'X-CSRF-Token': accessToken },
  })
    .then((response) => {
      if (!response.data) {
        window.localStorage.removeItem('cart')
        dispatch(setOpen(false))
      }
      mutate()
    })
    .then(() => gaRemoveFromCart(product, quantity))
    .then(() => dispatch(setLoading(false)))
}

export const handleQuantityChange = async (accessToken, cart, item, mutate, dispatch, newQuantity, oldQuantity) => {
  axios({
    method: 'patch',
    url: `/api/cart/${cart.uuid}/cart_items/${item.id}`,
    headers: { 'X-CSRF-Token': accessToken },
    data: { quantity: newQuantity },
  })
    .then(() => mutate())
    .then(() => {
      if (newQuantity > oldQuantity) {
        gaAddToCart(item, newQuantity - oldQuantity)
      } else if (newQuantity < oldQuantity) {
        gaRemoveFromCart(item, oldQuantity - newQuantity)
      }
    })
    .then(() => dispatch(setLoading(false)))
}

export default cartSlice.reducer

