import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCart from '@/public/hooks/use-cart'
import { handleQuantityChange, handleRemove, setLoading } from '@/public/redux/cartSlice'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import { numberToCurrency } from '@/shared/helpers/String'
import QuantityInput from '@/public/product/QuantityInput'
import CartMembershipToggle from '@/public/product/CartMembershipToggle'
import { accessToken } from '../../shared/helpers/CSRF'

export default function CartItem({
  data: item = null,
  quantity,
  subscription = false,
  pickupOrDelivery = null,
  customerIsMember,
  cartContainsLabOrTest,
  monthlyMembership,
  annualMembership,
}) {
  const verified = useSelector(selectVerified)
  const customerChoseLocalPickup = /pickup/i.test(pickupOrDelivery)
  const dispatch = useDispatch()
  const { cart, mutate } = useCart()

  const handleRemoveClick = (e) => {
    e.stopPropagation()

    dispatch(setLoading(true))
    handleRemove(accessToken, cart, item, mutate, dispatch, quantity)
  }

  const handleQuantityInputClick = (newQuantity) => {
    dispatch(setLoading(true))
    handleQuantityChange(accessToken, cart, item, mutate, dispatch, newQuantity, quantity)
  }

  return !item ? '' : (
    <div className="cart-list-item">

      {
        /*
         * BEGIN DESKTOP
         */
      }
      <div className="cart-list-item-desktop d-none d-md-flex flex-wrap w-100">
        <div className="cart-list-item-image">
          <a href={item.permalink}>
            <img src={item.featured_image_url} alt={item.featured_image_alt_text} />
          </a>
        </div>

        <div className="cart-list-item-details">
          <div className="cart-list-item-details-name h4">
            <a href={item.permalink} className="text-decoration-none d-block">
              { item.name }
            </a>
            { (subscription || item.category === 'membership')
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Subscription</div>}
          </div>

          <div className="cart-list-item-details-desc">
            <p className="my-0 py-0">{ item.sales_intro }</p>
            <p className="my-0 py-0">{ item.short_description }</p>
          </div>
          { item.quantifiable
            && <QuantityInput quantity={quantity} handleChange={(e) => handleQuantityInputClick(e)} /> }
        </div>

        <div className="cart-list-item-end">
          <div className="cart-list-item-price h5">
            <div>
              {
                  Boolean(item?.original_price) && Number(item.price) !== Number(item.original_price) ? (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>

                      <span className="d-block fw-lighter text-decoration-line-through" style={{ opacity: 0.4, fontSize: '88%' }}>
                        { numberToCurrency(item.original_price) }
                      </span>

                      { subscription && (
                      <>
                        { customerIsMember === false
                        && (
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          -
                          { item.calculated_subscription_savings_percentage }
                          {' '}
                          Subscribe & Save
                        </span>
                        )}
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          Renews every
                          {' '}
                          {item.subscription_frequency_in_days}
                          {' '}
                          days
                        </span>
                      </>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>
                      { subscription
                      && (
                      <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                        Renews every&nbsp;
                        { item.subscription_frequency_in_days == 7
                          ? 'week' : `${item.subscription_frequency_in_days} days`}
                      </span>
                      )}
                    </>
                  )
                }
            </div>

            { customerChoseLocalPickup
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Local Pickup</div>}
          </div>

          { item.category === 'membership' ? (
            !cartContainsLabOrTest
              && (
                <button
                  className="btn btn-link text-end"
                  type="button"
                  disabled={!verified}
                  onClick={(e) => handleRemoveClick(e)}
                >
                  Remove
                </button>
              )
          ) : (
            <button
              className="btn btn-link text-end"
              type="button"
              disabled={!verified}
              onClick={(e) => handleRemoveClick(e)}
            >
              Remove
            </button>
          )}
        </div>

        { item.category === 'membership' && (
          <div className="cart-list-item-membership-info">
            <span className="pe-3">
              <span className="bold pe-1">Monthly</span>
              { numberToCurrency(monthlyMembership.data.price) }
            </span>
            <CartMembershipToggle
              membership={item}
              monthlyMembership={monthlyMembership}
              annualMembership={annualMembership}
            />
            <div className="ps-3">
              <span>
                <span className="bold pe-1">Annual</span>
                { numberToCurrency(annualMembership.data.price) }
              </span>
              { item.sku === monthlyMembership.data.sku && (
                <div className="save-info">
                  <span>Save over </span>
                  $
                  { Math.ceil((monthlyMembership.data.price * 12) - annualMembership.data.price) }
                  <span id="savings" />
                  !
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {
        /*
         * END DESKTOP
         */
      }
      {
        /*
         * BEGIN MOBILE
         */
      }
      <div className="cart-list-item-mobile d-flex d-md-none">
        <div className="cart-list-item-image">
          <div>
            <a href={item.permalink}>
              <img src={item.featured_image_url} alt={item.featured_image_alt_text} />
            </a>
          </div>
          <div className="cart-list-item-details">
            <div className="cart-list-item-details-name h5">
              <a href={item.permalink} className="text-decoration-none d-block">
                { item.name }
              </a>
              { subscription
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Subscription</div>}
            </div>

            <div className={`cart-list-item-price h5 ${subscription ? 'border-top mt-3 pt-3' : ''}`}>
              <div>
                {
                  Boolean(item?.original_price) && Number(item.price) !== Number(item.original_price) ? (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>

                      <span className="d-block fw-lighter text-decoration-line-through" style={{ opacity: 0.4, fontSize: '88%' }}>
                        { numberToCurrency(item.original_price) }
                      </span>

                      { subscription && (
                      <>
                        { customerIsMember === false
                        && (
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          -
                          { item.calculated_subscription_savings_percentage }
                          {' '}
                          Subscribe & Save
                        </span>
                        )}
                        <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                          Renews every
                          {' '}
                          {item.subscription_frequency_in_days}
                          {' '}
                          days
                        </span>
                      </>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{ numberToCurrency(item.price) }</span>
                      { subscription
                      && (
                      <span className="d-block fw-lighter" style={{ opacity: 0.4, fontSize: '65%' }}>
                        Renews every&nbsp;
                        { item.subscription_frequency_in_days == 7
                          ? 'week' : `${item.subscription_frequency_in_days} days`}
                      </span>
                      )}
                    </>
                  )
                }
              </div>

              { customerChoseLocalPickup
                && <div className="d-inline-block badge bg-secondary mt-2 tiny">Local Pickup</div>}
            </div>

          </div>
        </div>
        <div className="cart-list-item-end">
          { item.quantifiable
            && <QuantityInput quantity={quantity} handleChange={(e) => handleQuantityInputClick(e)} /> }
          { item.category === 'membership' && (
            <div className="cart-list-item-membership-info">
              <span className="d-flex flex-sm-row flex-column align-items-sm-center text-center pe-1 pe-xs-3">
                <span className="fw-bold pe-sm-1 mb-0">Monthly</span>
                <span>{numberToCurrency(monthlyMembership.data.price)}</span>
              </span>
              <CartMembershipToggle
                membership={item}
                monthlyMembership={monthlyMembership}
                annualMembership={annualMembership}
              />
              <div className="d-flex flex-column flex-sm-row ps-1 ps-xs-3">
                <span className="d-flex flex-column flex-sm-row align-items-sm-center text-center mb-2 mb-sm-0">
                  <span className="fw-bold pe-sm-1">Annual</span>
                  <span>{numberToCurrency(annualMembership.data.price)}</span>
                </span>
                { item.sku === monthlyMembership.data.sku && (
                  <div className="save-info d-none d-xs-inline-block text-center text-sm-start">
                    <span>Save over </span>
                    $
                    {Math.floor((monthlyMembership.data.price * 12) - annualMembership.data.price)}
                    !
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        { item.category === 'membership' ? (
          !cartContainsLabOrTest
          && (
            <button
              className="btn btn-link text-end"
              type="button"
              disabled={!verified}
              onClick={(e) => handleRemoveClick(e)}
            >
              Remove
            </button>
          )
        ) : (
          <button
            className="btn btn-link text-end"
            type="button"
            disabled={!verified}
            onClick={(e) => handleRemoveClick(e)}
          >
            Remove
          </button>
        )}
      </div>
      {
        /*
         * END MOBILE
         */
      }

    </div>
  )
}
