function handleRecaptchaLoaded() {
  document.querySelectorAll('.recaptcha-submit-button').forEach((submit) => submit.classList.remove('disabled'))
  window.removeEventListener('recaptchaLoaded', handleRecaptchaLoaded)
}
if (!window.grecaptchaHandle) {
  window.addEventListener('recaptchaLoaded', handleRecaptchaLoaded)
} else {
  document.querySelectorAll('.recaptcha-submit-button').forEach((submit) => submit.classList.remove('disabled'))
}
$(document).on('submit', '.recaptcha-form', async (e) => {
  e.preventDefault()
  const action = e.target.dataset.recaptchaAction
  const response = await window.grecaptcha.enterprise.execute(window.grecaptchaHandle, { action })
  document.querySelectorAll('input.g-recaptcha-response').forEach((input) => {
    // eslint-disable-next-line no-param-reassign
    input.value = response
  })
  e.target.submit()
})
