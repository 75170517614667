import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import store from '@/admin/components/redux/store'

export default function Loader({ load: component, data }) {
  const SaveStatus = lazy(() => import('@/admin/components/SaveStatus'))
  const Modal = lazy(() => import('@/admin/components/Modal'))
  const Components = lazy(() => import('@/admin/components/Components'))

  const renderComponent = () => {
    switch (true) {
      case component === 'modal':
        return <Modal availableComponents={data.availableComponents} />

      case component === 'components':
        return (
          <Components
            recordId={data.recordId}
            data={data.data}
            availableComponents={data.availableComponents}
            availableIcons={data.availableIcons}
            directUploadUrl={data.directUploadUrl}
            errors={data.errors}
          />
        )

      case component === 'save_status':
        return <SaveStatus isNewRecord={data?.isNewRecord} />

      default:
        console.error('YOU MUST PASS IN COMPONENT STRING')
        return ''
    }
  }

  return (
    <Provider store={store}>
      <Suspense fallback="">
        { renderComponent() }
      </Suspense>
    </Provider>
  )
}
