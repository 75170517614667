export function gaAddToCart(product, quantity) {
  // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
  // and a list of productFieldObjects.
  window.dataLayer.filter((tag) => tag !== 'ecommerce') // Clear the previous ecommerce object.

  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      add: {
        products: [{
          item_name: product.name,
          item_id: product.id,
          price: product.price,
          item_category: product.category,
          quantity,
        }],
      },
    },
  })
}

export function gaRemoveFromCart(product, quantity) {
  // Measure the removal of a product from a shopping cart.
  window.dataLayer.filter((tag) => tag !== 'ecommerce') // Clear the previous ecommerce object.

  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [{
          item_name: product.name,
          item_id: product.id,
          price: product.price,
          item_category: product.category,
          quantity,
        }],
      },
    },
  })
}

export function gaPurchase(order) {
  // Send transaction data with a pageview if available
  // when the page loads. Otherwise, use an event when the transaction
  // data becomes available.

  window.dataLayer.filter((tag) => tag !== 'ecommerce')

  const trackedOrders = JSON.parse(localStorage.getItem('trackedPurchases')) || []

  if (!trackedOrders.includes(order.id)) {
    const products = order.cart.contents.map((content) => {
      const product = content.data

      let productObj = {
        item_name: product.name,
        item_id: product.id,
        price: product.price,
        item_category: product.category,
        quantity: content.quantity,
      }

      if (content.subscription) {
        productObj = { ...productObj, variant: 'new subscription' }
      }

      return productObj
    })

    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: order.id,
            revenue: order.payment_total,
            shipping: order.shipping_cost,
          },
          products,
        },
      },
    })

    trackedOrders.push(order.id)
    localStorage.setItem('trackedPurchases', JSON.stringify(trackedOrders))
  }
}
