import ReactRailsUJS from 'react_ujs'

// Support component names relative to this directory:
ReactRailsUJS.useContext(require.context('components', true))

// Prevent double mount on page load
ReactRailsUJS._firstTurboLoadSkipped = false
ReactRailsUJS.handleEvent('turbo:load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent('turbo:before-render', (e) => {
  ReactRailsUJS.unmountComponents(e.target)
})
ReactRailsUJS.handleEvent('turbo:before-frame-render', ReactRailsUJS.handleUnmount)
